<template>
    <Loading :isLoading="isLoading" />
    <html>
      <body class="" style="background: #FFF">
        <Header :localNavigation="false" v-on:go-to-back="changeGoToBack" />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
                <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
                    <img src="@/assets/back-arrow.svg">Atrás
                </div>
                <div v-if="!continueQuote" class="w-full md:w-4/5 mx-auto h-auto py-10 md:py-16 grid grid-cols-1 md:grid-cols-2">
                    <div class="px-5">
                        <p class="text-2xl md:text-3xl font-bold mb-5 md:mb-10 RobotoBold">Datos del Solicitante</p>
                        <div class="border shadow rounded border-gray-100 px-5 py-4">
                            <p class="text-lg font-light mb-5 RobotoMedium">Datos Personales</p>  
                            
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Nombre completo:</p> 
                                <p class="text-md font-light mb-2 RobotoLight">
                                    {{ detail.personal_data.full_name }}
                                </p>
                            </div>
                            <div class="grid grid-cols-2">
                                <div class="mb-2">
                                    <p class="text-sm font-light RobotoLight">Sexo:</p> 
                                    <p class="text-md font-light RobotoLight">{{ (detail.personal_data.gender.toLowerCase() == 'male')? 'Masculino': 'Femenino' }} </p>
                                </div>
                                <div class="mb-2">
                                    <p class="text-sm font-light RobotoLight">Edad:</p> 
                                    <p class="text-md font-light RobotoLight">{{ detail.personal_data.age }} años </p>
                                </div>
                            </div>
                            <div class="grid grid-cols-2">
                                <div class="mb-2"><p class="text-sm font-light RobotoLight">Estatura:</p> <p class="text-md font-light RobotoLight">{{ detail.personal_data.height }} Mts </p></div>
                                <div class="mb-2"><p class="text-sm font-light RobotoLight">Peso:</p> <p class="text-md font-light RobotoLight">{{ detail.personal_data.weight }} Kg </p></div>
                            </div>
                            <div class="mb-3 mt-5"><p class="text-lg font-light mb-2 RobotoMedium">Suma asegurada</p></div>
                            <div class="mb-2">
                                <p class="text-md font-light RobotoLight">{{  $filters.formatCurrencyWithDecimal(detail.assured_amount) }}</p>
                            </div>
                            
                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoMedium">Contacto</p></div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Teléfono:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatPhone(detail.contact_data.telephone) }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Celular:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatPhone(detail.contact_data.cellphone) }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Correo:</p> 
                                <p class="text-md font-light RobotoLight">{{ detail.contact_data.email }}</p>
                            </div>

                            <div class="grid grid-cols-2 grid-rows-3 mb-5 mt-10">
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Enfermedades:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.diseases.length==0">No</p>
                                        <p v-for="(desease, index) in detail.diseases" :key="index" class="mb-1">{{ desease.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Deportes:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.sports.length==0">No</p>
                                        <p v-for="(sport, index) in detail.sports" :key="index">{{ sport.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Profesión:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.occupation.length==0">No</p>
                                        <p v-for="(occupation, index) in detail.occupation" :key="index">{{ occupation.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Operaciones:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.surgery.length==0">No</p>
                                        <p v-for="(surgery, index) in detail.surgery" :key="index">{{ surgery.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Pasatiempos:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.hobbies.length==0">No</p>
                                        <p v-for="(hobby, index) in detail.hobbies" :key="index">{{ hobby.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular"> </p>
                                </div>
                            </div>

                        </div>
                        <input type="button" id="button_next" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-5 w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="confirm" value="Continuar">
                    </div>
                    <div class="px-10 py-16 hidden md:block">
                        <img :src="require('@/assets/Cuenta/vidaSimple/finished.svg')" alt="FONCABSA SFC" class="mx-auto">
                    </div>
                </div>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import { onBeforeMount, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import { getUserInfo, getTokenDecoden } from '@/helpers/tokenauth.js';
import { getLocalStorage, createLocalStorage, getSessionStorage, getTokenOfSessionStorage } from '@/helpers/storage.js';

import moment from "moment";
import Swal from "sweetalert2";
import Loading from "@/components/Loading/VueLoading.vue";

import DataQuotation from "@/classes/DataQuotation.js";
import DatetimeValidator from "@/classes/DatetimeValidator.js";
import WsQuotation from "@/api/WsQuotation.js";

import { getAgeByDate } from '@/helpers/dates.js';
import Quote from '@/classes/Quotation.js';

export default {
    setup(){
        const wsQuotation = new WsQuotation();
        const token = ref('');
        const recruimentData = ref({});
        const userData = ref({});
        const userId = ref(null);
        const dateTimeValidator = new DatetimeValidator();

        const router = useRouter();
        const route = useRoute();
        const category = ref('');
        const userInformation = ref(null);

        let counterGoBack = ref(0);
        let continueQuote = ref(false);

        const quoteClass = ref(null);        

        let detail = ref({
            personal_data:{
                full_name: "",
                age: 0,
                gender: "",
                height: 0,
                weight: 0
            },
            contact_data: {
                telephone: "",
                cellphone: "",
                email: ""
            },
            diseases: [],
            sports: [],
            occupation: [],
            surgery: [],
            hobbies: [],
            coverages: [],
            assured_amount: 0,
            smoke: ''
        });

        const quotationClass = ref(null);
        const quotationInformation = ref(null);
        const isLoading = ref(true)

        const quotationJson = ref({});

        onBeforeMount(async() => {
            quoteClass.value = new Quote();
            category.value = getCategory();
            userInformation.value = getSessionStorage('user-information');
            quotationClass.value = new DataQuotation();
            
            let quoteJson = getQuoteFromStorage();
            if(quoteJson){
                
                quoteJson = {
                    ...quoteJson.person,
                    ...quoteJson
                }

                quotationInformation.value = quoteJson;
                quotationClass.value = quotationClass.value.new(category.value, quoteJson);
                quotationJson.value = await quotationClass.value.execute();
            
                await loadInfo().finally(()=> {
                    isLoading.value = false;
                });
                
                getTokenQuotation();
                return;
            }

            router.push({path: '/cuenta/principal'});
        })

        function getQuoteFromStorage(){
            const product = getCategory(false);
            return getLocalStorage(`quote-${product}`);
        }

        function getCategory(replace = true){
            let product = route.params.product;
            return replace? product.replaceAll("-", " "): product;
        }

        const loadInfo = async() => {
            recruimentData.value = getLocalStorage('quote-recruitment-data')
            userData.value = getUserInfo();
            token.value = getTokenOfSessionStorage('login');

            
            userId.value = userInformation.value.work_info.foncabsa_person_id;

            await getQuoteInformation();
        }

        const getQuoteInformation = async () => {            
            detail.value.personal_data.full_name = [quotationInformation.value.person["name"],quotationInformation.value.person["middle_name"],quotationInformation.value.person["paternal_name"],quotationInformation.value.person["maternal_name"]].filter(x=>x).join(" ");
            // let birthday = moment(quotationInformation.value.person.birthday);
            // let today = moment();
            // let age = today.diff(birthday,"years");
            detail.value.personal_data.age = calculate_age()
            detail.value.personal_data.weight = quotationInformation.value.weight;
            detail.value.personal_data.height = quotationInformation.value.height;
            detail.value.personal_data.gender = (quotationInformation.value.person.gender)? (quotationInformation.value.person.gender=="femenino")? 'female': 'male' :'';

            detail.value.contact_data.email = (quotationInformation.value.email)? quotationInformation.value.email: '';
            detail.value.contact_data.telephone = (quotationInformation.value.phone)? quotationInformation.value.phone: '' ;
            detail.value.contact_data.cellphone = (quotationInformation.value.cellphone)? quotationInformation.value.cellphone: '' ;

            detail.value.diseases = (quotationInformation.value.diseases)? quotationInformation.value.diseases : [];
            detail.value.sports = (quotationInformation.value.sports)? quotationInformation.value.sports : [];
            detail.value.surgery = (quotationInformation.value.surgery)? quotationInformation.value.surgery : [];
            detail.value.hobbies = (quotationInformation.value.hobbies)? quotationInformation.value.hobbies: [];

            let coverages = [...new Set(quotationInformation.value.coverage)];
            coverages.push('basic');

            detail.value.coverages = (coverages.length>0)? coverages : [];
            detail.value.assured_amount = quotationInformation.value.assured_amount;
            detail.value.smoke = quotationInformation.value.is_smoker;

            detail.value.occupation = (quotationInformation.value.occupations)? quotationInformation.value.occupations : [];
        } 

        function calculate_age()
        {
            const data_quotation = getQuoteFromStorage();
            const dateFormated = dateTimeValidator.normalizeDateFormat(data_quotation.person.birthday);
            
            if(is_aditional())
            {   
                return getAgeByDate(dateFormated.split("/").reverse().join("-"));
            }
            return getAgeByDate(dateFormated.split("/").reverse().join("-"));
        }

        function is_aditional()
        {
            const quote = quoteClass.value.get(getCategory(false));
            return quote.is_additional_insured == "Para Adicional";
        }

        const changeGoToBack = () => {
            counterGoBack.value = counterGoBack.value+1;
        }

        const next = () => {
            router.push('cotizacion')
        }

        const getTokenQuotation = async () => {
            token.value = await wsQuotation.getToken().then(resp => resp.data.response.token_auth ).catch(err => {
                if(err.response.status != 500) {
                    console.log("!Error 422:", err.response.data.response.message)
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Ha ocurrido un error con el servicio de cotización",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }
                return null;
            });
        }

        const generateQuotation = async() => {
            
            const response = await quotationClass.value.post(quotationJson.value).then(response => {
                return {status: true, code_status: response.status, data: response.data.response, message: ""};
            }).catch(error => {
                const message = error.response.data.response != undefined? error.response.data.response.message: error.response.data.mensaje != undefined? error.response.data.mensaje: "Ha ocurrido un error inesperado" ;
                return {status: false, code_status: error.response.status, data: null, message: message};
            });
            
            if(!response.status){
                showMessage((response.code_status == 422)? 'info': 'error', (response.code_status == 422)? 'Aviso': 'Error', response.message);
                return;
            }

            return response;
        }

        const showMessage = (type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B") => {
            Swal.fire({
                title: title,
                html: message,
                icon: type,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor
            });
        }

        const confirm = async () => {
            isLoading.value = true;
            if(token.value){
                const result = await generateQuotation().finally(() => { isLoading.value = false; });
                if(result){
                    createLocalStorage('quote-description', result.data);
                    next();
                }
            }else{
                Swal.fire({
                    title: "Aviso",
                    html: "Servicio de cotización no disponible, por favor inténtelo de nuevo más tarde",
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                }); 
            }

            isLoading.value = false;
        }

        const redirect_history = () => router.go(-1);

        return {
            category,
            counterGoBack,
            continueQuote,
            detail,
            isLoading,
            next,
            confirm,
            changeGoToBack,
            getAgeByDate,
            redirect_history
        }
    },
    components:{
        Header,
        MenuLateralMobile,
        MenuLateral,
        Loading
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>